body {
    padding: 0;
    font-family: 'Post Grotesk', sans-serif;
    margin: 0;

    .container {
        max-width: 1440px;
        max-width: 1440px;
        margin: 0 auto;
    }
}

header {
    width: 100%;
    height: 130px;
    //box-shadow: 0 13px 20px rgba(0,0,0,0.25);
    border-bottom: 1px solid #e6e6e6;
    position: relative;

    background-image: url('../img/logo.png');
    background-size: auto 100px; 
    background-repeat: no-repeat;
    background-position: center center;

    .menu-overlay {
        position: absolute;
        background-color: #f0f6fd;
        width: calc(100% - 20px);
        top: 150px;
        padding: 10px;
        text-align: center;
        z-index: 99999;
        display: none;

        a {
            text-decoration: none;
            color: #000;
            margin-right: 20px;
            font-size: 14px;
        }
    }

    .icon-bar {
        position: absolute;
        bottom: 20px;
        margin-left: 50px;
        width: calc(100% - 50px);

        .shopping-cart,
        .location,
        .burger-menu {
            display: inline-block;
            cursor: pointer;
            position: relative;

            img {
                height: 25px;
                width: 25px;
            }

            &__inner-1,
            &__inner-2 {
                width: 30px;
                height: 2px;
                border-radius: 2px;
                background-color: #000;
                transition: 0.4s;
                display: block;
                transform-origin: 50% 50%;
                position: absolute;
            }

            &__inner-1 {
                top: 18px;

                &.rotate {
                    transform: rotate(-45deg);
                    margin: 0;
                    top: 15px;
                }
            }

            &__inner-2 {
                top: 12px;

                &.rotate {
                    transform: rotate(45deg);
                    margin: 0;
                    top: 15px;
                }
            }
        }
        .cancel-container {
            position: absolute;
            top: 2px;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
        }

        .cancel-popup {
            position: absolute;
            right: 2px;
            top: 2px;
            width: 10px;
            height: 10px;
            

            &:after,
            &:before {
                position: absolute;
                left: -5px;
                top: 5px;
                content: ' ';
                height: 15px;
                width: 2px;
                background-color: #000;
                border-radius: 2px;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }

        .burger-menu,
        .location {
            margin-right: 40px;
        }

        .burger-menu {
            height: 30px;
            width: 30px;
            vertical-align: -2px;
        }

        .shopping-cart {
            float: right;
            margin-right: 50px;
            margin-top: 5px;
        }

        .main-logo {
            display: inline-block;

            img {
                height: 100px;
            }
        }
        

        .location__overlay {
            background-color: #f0f6fd;
            width: 250px;
            position: absolute;
            top: 40px;
            z-index: 99999;
            padding: 20px;
            border-radius: 5px;
            font-weight: 100;
            box-shadow: 0 0 4px rgba(0,0,0,0.25);
            display: none;

            &:after {
                content: " ";
                position: absolute;
                bottom: 100%;
                left: 50%;
                margin-left: -66px;
                border-width: 8px;
                border-style: solid;
                border-color: transparent transparent #f0f6fd transparent;
            }

            .headline {
                font-weight: 400;
                margin-bottom: 3px;
            }

            p {
                margin: 0;
            }

            .opening-hours {
                width: 110px;
                display: inline-block;
                margin-top: 5px;

                h2 {
                    font-size: 12px;
                    margin: 0;
                }

                span {
                    font-size: 12px;
                }
            }
        }

        .shopping-cart__overlay {
            background-color: #f0f6fd;
            width: 250px;
            position: absolute;
            top: 40px;
            z-index: 99999;
            padding: 20px;
            border-radius: 5px;
            font-weight: 100;
            box-shadow: 0 0 4px rgba(0,0,0,0.25);
            right: 10px;
            display: none;

            &:after {
                content: " ";
                position: absolute;
                bottom: 100%;
                left: 50%;
                margin-left: 85px;
                border-width: 8px;
                border-style: solid;
                border-color: transparent transparent #f0f6fd transparent;
            }

            .headline {
                font-weight: 400;
                margin-bottom: 3px;
            }

            p {
                margin: 0;
            }

            .order-info {
                font-size: 10px;
            }

            .contact-info {
                margin: 10px 0;

                img {
                    width: 16px;
                    height: 16px;
                    vertical-align: -4px;
                    margin-right: 10px;
                }

                a {
                    text-decoration: none;
                    color: #000;
                }
            }
        }
    }
}

.impressum {
    text-align: left;
    margin-top: 20px;
    cursor: pointer;
    display: block;
    text-decoration: underline;
}

.impressum-container {
    margin: 50px 100px;
    display: none;

    p {
        font-weight: 100;
    }
}

.content-wrapper {
    margin: 50px 100px;
    
    &:after {
        content: "";
        clear: both;
        display: table;
    }
}

.header-slider {
    width: 100%;
    max-height: 800px;
    overflow: hidden;

    &__image {
        background: no-repeat center;
        width: 100%;
        max-height: 800px;
        background-size: 100% auto; 
        padding-top: 56.25%;
    }

    img {
        width: 100%;
    }

    &:hover {
        .flickity-button {
            opacity: 1;
            transition: 0.2s;
        }
    }
}

.headline {
    text-align: center;
    margin-bottom: 50px;
    img {
        max-width: 280px;
    }
}

.welcome-text {
    width: calc(60% - 50px);
    display: inline-block;
    margin-bottom: 15px;

    h1 {
        font-size: 30px;
        margin-top: 0;
    }

    p {
        font-size: 18px;
        font-weight: 100;

    }

    a {
        color: #000;
    }
}

.flickity-button {
    opacity: 0;
    background-color: transparent;
    transition: 0.2s;

    &:hover {
        background: transparent;
    }
    &:focus {
        outline: none;
    }

    .flickity-button-icon {
        fill: #fff;
    }
}

.order-books {
    width: calc(40% - 50px);
    display: inline-block;
    vertical-align: top;
    position: relative;
    float: right;

    &__container {
        background-color: #d2ecff;
        border-radius: 10px;
        padding: 25px 25px 25px 20px;
        max-width: 315px;

        img {
            height: 18px;
            width: 18px;
            vertical-align: -4px;
            margin-right: 10px;
        }
    }

    h2 {
        font-size: 18px;
        margin-top: 0;
    }

    a {
        text-decoration: none;
        color: #000;
        font-weight: 100;
    }

    p {
        font-weight: 100;
        margin: 0;
        margin-bottom: 5px;

        &:last-of-type {
            margin: 0;
        }

        &.order-books__info-text {
            margin-top: 18px;
            font-size: 12px;
        }
    }

    &.overlay {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 35%;
        z-index: 9999;
        transform: translate3d(-50%, -50%, 0);
        
        h2 {
            padding-right: 20px;
            margin-bottom: 40px;
        }

        .close {
            display: inline-block;
            position: absolute;
            right: 15px;
            top: 15px;

            img {
                cursor: pointer;
                margin-right: 0;
                width: 20px;
                height: 20px;
                
                * {
                    fill: #000;
                }
            }
        }

        .order-books__container {
            width: 100%;
            max-width: none;
            box-sizing: border-box;
        }
    }
}

.overlay-shadow {
    position: fixed;
    height: 100%;
    z-index: 9998;
    top: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
}

#map {
    height: 400px;

}

footer {
    padding: 30px 100px;
    background-color: #f2f2f2;
    
    .footer-element {
        width: calc(33% - 1px);
        display: inline-block;
        vertical-align: top;

        h2 {
            font-size: 18px;
        }

        p {
            font-weight: 100;
            margin: 0;
        }

        a {
            text-decoration: none;
            color: #000;
            font-weight: 100;
        }
    }
}

@media only screen and (max-height: 1050px) and (min-width: 800px) {
    .header-slider {
        width: 100%;
        max-height: 600px;

        &__image {
            padding-top: 39.25%;
        }
    }
}


@media only screen and (max-width: 1024px) {
    body {
        header {
            height: 120px;
            background-size: auto 80px;

            .menu-overlay {
                top: 120px;
            }
        }
    }
}

@media only screen and (max-width: 950px) {
    body {
        .content-wrapper {
            margin: 50px;
        }
        
         .welcome-text {
            width: 100%;

            h1 {
                font-size: 28px;
            }

            p {
                font-size: 16px;
            }
        }

        .order-books {
            display: block;
            width: 100%;

            &__container {
                max-width: 450px;
            }

            &.overlay {
                width: 90%;
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    body {

        .impressum-container {
            .black-border {
                width: calc(100% - 100px);
                margin: 50px 50px 20px 50px;
            }

            a {
                color: #000;
            }
            ul {
                li {
                    font-weight: 100;
                }
            }
        }

        footer {
             padding: 30px 50px;

            .footer-element {
                width: calc(50% - 50px);
                display: inline-block;
            }
        }
    }
}


@media only screen and (max-width: 500px) {
    body {
        .content-wrapper {
            margin: 30px;
        }

        header {
            height: 80px;
            background-size: auto 60px;

            .menu-overlay {
                top: 80px;
            }

            .icon-bar {
                margin-left: 25px;

                .burger-menu,
                .location {
                    margin-right: 20px;
                }

                .location__overlay {
                    top: 50px;

                    &:after {
                        margin-left: -86px;
                    }
                }

                .shopping-cart {
                    margin-right: 25px;

                    &__overlay {
                        top: 50px;
                        &:after {
                            margin-left: 112px;
                        }
                    }
                }

                .burger-menu__inner {
                    width: 20px;
                    margin: 2px 0;
                    height: 1px;
                }
            }
        }

        .headline {
            display: none;
        }

        .welcome-text {
            h1 {
                font-size: 22px;
            }
        }

        footer {
             padding: 30px 50px;

            .footer-element {
                width: 100%;
                display: block;
            }
        }
    }
}