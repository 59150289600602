@font-face {
  font-family: 'Post Grotesk';
  src: url('https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Light.eot');
  src: url('https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Light.eot?#iefix') format('embedded-opentype'),
  url('https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Light.woff') format('woff'),
  url('https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Light.ttf') format('truetype'),
  url('https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Light.svg') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Post Grotesk';
  src: url('https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Book.eot');
  src: url('https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Book.eot?#iefix') format('embedded-opentype'),
  url('https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Book.woff') format('woff'),
  url('https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Book.ttf') format('truetype'),
  url('https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Book.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Post Grotesk';
  src: url('https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Medium.eot');
  src: url('https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
  url('https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Medium.woff') format('woff'),
  url('https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Medium.ttf') format('truetype'),
  url('https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Medium.svg') format('svg');
  font-weight: bold;
  font-style: normal;
}