.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: 0;
}

.flickity-viewport {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.flickity-slider {
  width: 100%;
  height: 100%;
  position: absolute;
}

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.flickity-button {
  color: #333;
  background: #ffffffbf;
  border: none;
  position: absolute;
}

.flickity-button:hover {
  cursor: pointer;
  background: #fff;
}

.flickity-button:focus {
  outline: 0;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: .6;
}

.flickity-button:disabled {
  opacity: .3;
  cursor: auto;
  pointer-events: none;
}

.flickity-button-icon {
  fill: #333;
}

.flickity-prev-next-button {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  left: 10px;
  right: auto;
}

.flickity-prev-next-button .flickity-button-icon {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 20%;
  left: 20%;
}

.flickity-page-dots {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: 1;
  list-style: none;
  position: absolute;
  bottom: -25px;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  width: 10px;
  height: 10px;
  opacity: .25;
  cursor: pointer;
  background: #333;
  border-radius: 50%;
  margin: 0 8px;
  display: inline-block;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

body {
  margin: 0;
  padding: 0;
  font-family: Post Grotesk, sans-serif;
}

body .container {
  max-width: 1440px;
  max-width: 1440px;
  margin: 0 auto;
}

header {
  width: 100%;
  height: 130px;
  background-image: url("logo.39142528.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100px;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
}

header .menu-overlay {
  width: calc(100% - 20px);
  text-align: center;
  z-index: 99999;
  background-color: #f0f6fd;
  padding: 10px;
  display: none;
  position: absolute;
  top: 150px;
}

header .menu-overlay a {
  color: #000;
  margin-right: 20px;
  font-size: 14px;
  text-decoration: none;
}

header .icon-bar {
  width: calc(100% - 50px);
  margin-left: 50px;
  position: absolute;
  bottom: 20px;
}

header .icon-bar .shopping-cart, header .icon-bar .location, header .icon-bar .burger-menu {
  cursor: pointer;
  display: inline-block;
  position: relative;
}

header .icon-bar .shopping-cart img, header .icon-bar .location img, header .icon-bar .burger-menu img {
  height: 25px;
  width: 25px;
}

header .icon-bar .shopping-cart__inner-1, header .icon-bar .shopping-cart__inner-2, header .icon-bar .location__inner-1, header .icon-bar .location__inner-2, header .icon-bar .burger-menu__inner-1, header .icon-bar .burger-menu__inner-2 {
  width: 30px;
  height: 2px;
  transform-origin: 50%;
  background-color: #000;
  border-radius: 2px;
  transition: all .4s;
  display: block;
  position: absolute;
}

header .icon-bar .shopping-cart__inner-1, header .icon-bar .location__inner-1, header .icon-bar .burger-menu__inner-1 {
  top: 18px;
}

header .icon-bar .shopping-cart__inner-1.rotate, header .icon-bar .location__inner-1.rotate, header .icon-bar .burger-menu__inner-1.rotate {
  margin: 0;
  top: 15px;
  transform: rotate(-45deg);
}

header .icon-bar .shopping-cart__inner-2, header .icon-bar .location__inner-2, header .icon-bar .burger-menu__inner-2 {
  top: 12px;
}

header .icon-bar .shopping-cart__inner-2.rotate, header .icon-bar .location__inner-2.rotate, header .icon-bar .burger-menu__inner-2.rotate {
  margin: 0;
  top: 15px;
  transform: rotate(45deg);
}

header .icon-bar .cancel-container {
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 0;
}

header .icon-bar .cancel-popup {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 2px;
  right: 2px;
}

header .icon-bar .cancel-popup:after, header .icon-bar .cancel-popup:before {
  content: " ";
  height: 15px;
  width: 2px;
  background-color: #000;
  border-radius: 2px;
  position: absolute;
  top: 5px;
  left: -5px;
}

header .icon-bar .cancel-popup:before {
  transform: rotate(45deg);
}

header .icon-bar .cancel-popup:after {
  transform: rotate(-45deg);
}

header .icon-bar .burger-menu, header .icon-bar .location {
  margin-right: 40px;
}

header .icon-bar .burger-menu {
  height: 30px;
  width: 30px;
  vertical-align: -2px;
}

header .icon-bar .shopping-cart {
  float: right;
  margin-top: 5px;
  margin-right: 50px;
}

header .icon-bar .main-logo {
  display: inline-block;
}

header .icon-bar .main-logo img {
  height: 100px;
}

header .icon-bar .location__overlay {
  width: 250px;
  z-index: 99999;
  background-color: #f0f6fd;
  border-radius: 5px;
  padding: 20px;
  font-weight: 100;
  display: none;
  position: absolute;
  top: 40px;
  box-shadow: 0 0 4px #00000040;
}

header .icon-bar .location__overlay:after {
  content: " ";
  border: 8px solid #0000;
  border-bottom-color: #f0f6fd;
  margin-left: -66px;
  position: absolute;
  bottom: 100%;
  left: 50%;
}

header .icon-bar .location__overlay .headline {
  margin-bottom: 3px;
  font-weight: 400;
}

header .icon-bar .location__overlay p {
  margin: 0;
}

header .icon-bar .location__overlay .opening-hours {
  width: 110px;
  margin-top: 5px;
  display: inline-block;
}

header .icon-bar .location__overlay .opening-hours h2 {
  margin: 0;
  font-size: 12px;
}

header .icon-bar .location__overlay .opening-hours span {
  font-size: 12px;
}

header .icon-bar .shopping-cart__overlay {
  width: 250px;
  z-index: 99999;
  background-color: #f0f6fd;
  border-radius: 5px;
  padding: 20px;
  font-weight: 100;
  display: none;
  position: absolute;
  top: 40px;
  right: 10px;
  box-shadow: 0 0 4px #00000040;
}

header .icon-bar .shopping-cart__overlay:after {
  content: " ";
  border: 8px solid #0000;
  border-bottom-color: #f0f6fd;
  margin-left: 85px;
  position: absolute;
  bottom: 100%;
  left: 50%;
}

header .icon-bar .shopping-cart__overlay .headline {
  margin-bottom: 3px;
  font-weight: 400;
}

header .icon-bar .shopping-cart__overlay p {
  margin: 0;
}

header .icon-bar .shopping-cart__overlay .order-info {
  font-size: 10px;
}

header .icon-bar .shopping-cart__overlay .contact-info {
  margin: 10px 0;
}

header .icon-bar .shopping-cart__overlay .contact-info img {
  width: 16px;
  height: 16px;
  vertical-align: -4px;
  margin-right: 10px;
}

header .icon-bar .shopping-cart__overlay .contact-info a {
  color: #000;
  text-decoration: none;
}

.impressum {
  text-align: left;
  cursor: pointer;
  margin-top: 20px;
  text-decoration: underline;
  display: block;
}

.impressum-container {
  margin: 50px 100px;
  display: none;
}

.impressum-container p {
  font-weight: 100;
}

.content-wrapper {
  margin: 50px 100px;
}

.content-wrapper:after {
  content: "";
  clear: both;
  display: table;
}

.header-slider {
  width: 100%;
  max-height: 800px;
  overflow: hidden;
}

.header-slider__image {
  width: 100%;
  max-height: 800px;
  background: center / 100% no-repeat;
  padding-top: 56.25%;
}

.header-slider img {
  width: 100%;
}

.header-slider:hover .flickity-button {
  opacity: 1;
  transition: all .2s;
}

.headline {
  text-align: center;
  margin-bottom: 50px;
}

.headline img {
  max-width: 280px;
}

.welcome-text {
  width: calc(60% - 50px);
  margin-bottom: 15px;
  display: inline-block;
}

.welcome-text h1 {
  margin-top: 0;
  font-size: 30px;
}

.welcome-text p {
  font-size: 18px;
  font-weight: 100;
}

.welcome-text a {
  color: #000;
}

.flickity-button {
  opacity: 0;
  background-color: #0000;
  transition: all .2s;
}

.flickity-button:hover {
  background: none;
}

.flickity-button:focus {
  outline: none;
}

.flickity-button .flickity-button-icon {
  fill: #fff;
}

.order-books {
  width: calc(40% - 50px);
  vertical-align: top;
  float: right;
  display: inline-block;
  position: relative;
}

.order-books__container {
  max-width: 315px;
  background-color: #d2ecff;
  border-radius: 10px;
  padding: 25px 25px 25px 20px;
}

.order-books__container img {
  height: 18px;
  width: 18px;
  vertical-align: -4px;
  margin-right: 10px;
}

.order-books h2 {
  margin-top: 0;
  font-size: 18px;
}

.order-books a {
  color: #000;
  font-weight: 100;
  text-decoration: none;
}

.order-books p {
  margin: 0 0 5px;
  font-weight: 100;
}

.order-books p:last-of-type {
  margin: 0;
}

.order-books p.order-books__info-text {
  margin-top: 18px;
  font-size: 12px;
}

.order-books.overlay {
  width: 35%;
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.order-books.overlay h2 {
  margin-bottom: 40px;
  padding-right: 20px;
}

.order-books.overlay .close {
  display: inline-block;
  position: absolute;
  top: 15px;
  right: 15px;
}

.order-books.overlay .close img {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right: 0;
}

.order-books.overlay .close img * {
  fill: #000;
}

.order-books.overlay .order-books__container {
  width: 100%;
  max-width: none;
  box-sizing: border-box;
}

.overlay-shadow {
  height: 100%;
  z-index: 9998;
  width: 100%;
  background: #0006;
  position: fixed;
  top: 0;
}

#map {
  height: 400px;
}

footer {
  background-color: #f2f2f2;
  padding: 30px 100px;
}

footer .footer-element {
  width: calc(33% - 1px);
  vertical-align: top;
  display: inline-block;
}

footer .footer-element h2 {
  font-size: 18px;
}

footer .footer-element p {
  margin: 0;
  font-weight: 100;
}

footer .footer-element a {
  color: #000;
  font-weight: 100;
  text-decoration: none;
}

@media only screen and (max-height: 1050px) and (min-width: 800px) {
  .header-slider {
    width: 100%;
    max-height: 600px;
  }

  .header-slider__image {
    padding-top: 39.25%;
  }
}

@media only screen and (max-width: 1024px) {
  body header {
    height: 120px;
    background-size: auto 80px;
  }

  body header .menu-overlay {
    top: 120px;
  }
}

@media only screen and (max-width: 950px) {
  body .content-wrapper {
    margin: 50px;
  }

  body .welcome-text {
    width: 100%;
  }

  body .welcome-text h1 {
    font-size: 28px;
  }

  body .welcome-text p {
    font-size: 16px;
  }

  body .order-books {
    width: 100%;
    display: block;
  }

  body .order-books__container {
    max-width: 450px;
  }

  body .order-books.overlay {
    width: 90%;
  }
}

@media only screen and (max-width: 900px) {
  body .impressum-container .black-border {
    width: calc(100% - 100px);
    margin: 50px 50px 20px;
  }

  body .impressum-container a {
    color: #000;
  }

  body .impressum-container ul li {
    font-weight: 100;
  }

  body footer {
    padding: 30px 50px;
  }

  body footer .footer-element {
    width: calc(50% - 50px);
    display: inline-block;
  }
}

@media only screen and (max-width: 500px) {
  body .content-wrapper {
    margin: 30px;
  }

  body header {
    height: 80px;
    background-size: auto 60px;
  }

  body header .menu-overlay {
    top: 80px;
  }

  body header .icon-bar {
    margin-left: 25px;
  }

  body header .icon-bar .burger-menu, body header .icon-bar .location {
    margin-right: 20px;
  }

  body header .icon-bar .location__overlay {
    top: 50px;
  }

  body header .icon-bar .location__overlay:after {
    margin-left: -86px;
  }

  body header .icon-bar .shopping-cart {
    margin-right: 25px;
  }

  body header .icon-bar .shopping-cart__overlay {
    top: 50px;
  }

  body header .icon-bar .shopping-cart__overlay:after {
    margin-left: 112px;
  }

  body header .icon-bar .burger-menu__inner {
    width: 20px;
    height: 1px;
    margin: 2px 0;
  }

  body .headline {
    display: none;
  }

  body .welcome-text h1 {
    font-size: 22px;
  }

  body footer {
    padding: 30px 50px;
  }

  body footer .footer-element {
    width: 100%;
    display: block;
  }
}

@font-face {
  font-family: Post Grotesk;
  src: url("https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Light.eot");
  src: url("https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Light.eot?#iefix") format("embedded-opentype"), url("https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Light.woff") format("woff"), url("https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Light.ttf") format("truetype"), url("https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Light.svg") format("svg");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Post Grotesk;
  src: url("https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Book.eot");
  src: url("https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Book.eot?#iefix") format("embedded-opentype"), url("https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Book.woff") format("woff"), url("https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Book.ttf") format("truetype"), url("https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Book.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Post Grotesk;
  src: url("https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Medium.eot");
  src: url("https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Medium.eot?#iefix") format("embedded-opentype"), url("https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Medium.woff") format("woff"), url("https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Medium.ttf") format("truetype"), url("https://meichsner-dennerlein.debd.com/fonts/PostGrotesk-Medium.svg") format("svg");
  font-weight: bold;
  font-style: normal;
}

/*# sourceMappingURL=index.0ef568ad.css.map */
